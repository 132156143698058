//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OfferForm from '@/modules/offer/components/offer-form';

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';

export default {
  name: 'app-offer-form-modal',
  // props: ['visible', 'id'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
  },

  components: {
    [OfferForm.name]: OfferForm,
  },
  
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  data() {
    return {
      // successDialog: true,
    };
  },
  computed: {
    ...mapGetters({
      record: 'offer/form/record',
      findLoading: 'offer/form/findLoading',
      saveLoading: 'offer/form/saveLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
  },

  methods: {
    ...mapActions({
      doFind: 'offer/form/doFind',
      doNew: 'offer/form/doNew',
      doUpdate: 'offer/form/doUpdate',
      doCreate: 'offer/form/doCreate',
    }),
    i18n(key, args) {
      return this.$t(key, args);
      // return i18n(code)
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } else {
        console.log('payload values',payload.values)
        const isCreated = await this.doCreate(payload.values);
        this.$emit('close');
        if (isCreated) {
          this.$emit('success');
        }
      }
    },
  },
};
