import { render, staticRenderFns } from "./saryah-offer-view-modal.vue?vue&type=template&id=33966aa4&scoped=true&"
import script from "./saryah-offer-view-modal.vue?vue&type=script&lang=js&"
export * from "./saryah-offer-view-modal.vue?vue&type=script&lang=js&"
import style0 from "./saryah-offer-view-modal.vue?vue&type=style&index=0&id=33966aa4&lang=scss&scoped=true&"
import style1 from "./saryah-offer-view-modal.vue?vue&type=style&index=1&id=33966aa4&lang=scss&scoped=true&"
import style2 from "./saryah-offer-view-modal.vue?vue&type=style&index=2&id=33966aa4&lang=scss&scoped=true&"
import style3 from "./saryah-offer-view-modal.vue?vue&type=style&index=3&id=33966aa4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33966aa4",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QSpinnerIos,QImg,QTabs,QTab,QToggle,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QSpinnerIos,QImg,QTabs,QTab,QToggle})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
