//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SaryahOfferListTable from '@/modules/offer/components/saryah-offer-list-table.vue';
import StoreOfferListTable from '@/modules/offer/components/store-offer-list-table.vue';
import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/compat/database';    // for realtime database
import 'firebase/compat/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

export default {
  name: 'app-offer-list-page',

  components: {
    [SaryahOfferListTable.name]: SaryahOfferListTable,
    [StoreOfferListTable.name]: StoreOfferListTable,
    [OfferFormModal.name]: OfferFormModal,
  },
  data() {
    return {
      tab: 'saryah',
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      rows: 'offer/list/rows',
      count: 'offer/list/count',
      loading: 'offer/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new OfferPermissions(this.currentUser).create;
    },
  },

  async created() {
    firebase.firestore().collection('offer').onSnapshot(async querySnapshot => {
       console.log(`Received query snapshot of size ${querySnapshot.size}`);
      this.doFilterInBackground();
      // this.doFilter();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
  },
  async mounted() {
    await this.doFilter();
  },
  methods: {
    ...mapActions({
      doFetch: 'offer/list/doFetch',
      doFetchInBackground: 'offer/list/doFetchInBackground',
    }),
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    onModalSuccess() {
      this.successDialog = true
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      // const filter = {}
      const filter = { type: this.tab ? this.tab :  'saryah' }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      // const filter = {}
      const filter = { type: this.tab ? this.tab :  'saryah' }
      return this.doFetchInBackground({
        filter,
      });
    },
  },

};
