//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { OfferModel } from '@/modules/offer/offer-model';
import { getLanguageCode } from '@/i18n';

const { fields } = OfferModel;
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.description,
  fields.image,
  fields.price,
  fields.percentage,
  fields.oldPrice,
  fields.type,
]);

export default {
  name: 'app-offer-form',
  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      step: 1,
      active: false,
      model: null,
      rules: formSchema.rules(),
      language: getLanguageCode(),
    };
  },

  async created() {
    this.model = formSchema.initialValues(this.record || {}); 
    console.log('model',this.model)
    console.log('fields',this.fields)

    if (!this.isEditing) {
      this.model.title = {
        en: undefined,
        ar: undefined,
      }
      this.model.description = {
        en: undefined,
        ar: undefined,
      }
      this.model.price = undefined
      this.model.percentage = undefined
      this.model.oldPrice = undefined
    } else if (this.model.image && !Array.isArray(this.model.image)) {
      // this.model.image.length ? this.model.image = [this.model.image] : undefined
      this.model.image = [this.model.image]
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({

    }),
    i18n(key, args) {
      // return i18n(key, args)
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    async doSubmit() {
      if (this.step < 3) {
        return this.$refs.stepper.next()
      }
      console.log('this.model in do submit', this.model[fields.price.name])
      const { id, ...values } = formSchema.cast(this.model);
      // values.type = 'saryah'
      this.model.image.length
        ? values.image = this.model.image[0]
        : values.image = null
      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
