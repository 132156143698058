//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
import i18n from '@/vueI18n'
import moment from 'moment';

import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';
import SaryahOfferViewModal from '@/modules/offer/components/saryah-offer-view-modal.vue';

import { OfferModel } from '@/modules/offer/offer-model';
const { fields } = OfferModel;

export default {
  name: 'app-saryah-offer-list-table',

  components: {
    [OfferFormModal.name]:OfferFormModal,
    [SaryahOfferViewModal.name]:SaryahOfferViewModal,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
    console.log('Rows ==> ', this.rows.length);
  },

  data() {
    return {
      filter: '',
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'title',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'description',
          field: 'description',
          label: 'description',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'price',
          field: 'price',
          label: 'price',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'oldPrice',
          field: 'oldPrice',
          label: 'oldPrice',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'percentage',
          field: 'percentage',
          label: 'percentage',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'createdAt',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'actions',
          align: 'center',
        },
      ],
      modalVisible: false,
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },
  computed: {
    ...mapGetters({
      rows: 'offer/list/rows',
      count: 'offer/list/count',
      // pagination: 'offer/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'offer/list/loading',
      saveLoading: 'offer/form/saveLoading',

      backgroundLoading: 'offer/list/backgroundLoading',
      destroyLoading: 'offer/destroy/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    loadingAndUpdate(){
      return this.loading || this.saveLoading || this.destroyLoading
    },
    language(){
      return i18n.locale
    },
    hasPermissionToEdit() {
      return new OfferPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new OfferPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'offer/list/doChangeSort',
      doChangePaginationCurrentPage: 'offer/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'offer/list/doChangePaginationPageSize',
      doMountTable: 'offer/list/doMountTable',
      doDestroy: 'offer/destroy/doDestroy',
      // doFetchInBackground: 'offer/list/doFetchInBackground',
      // doFetch: 'offer/list/doFetch',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      switch (fieldName) {
        case 'price':
        case 'oldPrice':
          return this.currentLanguageCode == 'ar'
            ? `${val} ريال سعودي ` 
            : `${val} SAR`  
        default:
          return val ? val : 'ـــــــ';
      }
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      console.log('language in presenterMap',this.language)
      const val = OfferModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : val['en']
    },
    presenterDate(val) {
      // const val = OfferModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(OfferModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = OfferModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};
