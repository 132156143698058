//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';

export default {
  name: 'app-saryah-offer-view-modal',
  props: ['visible', 'id', 'record'],

  data() {
    return {
      // tab: getLanguageCode() == 'ar' ? false : true,
      tab: this.currentLanguageCode == 'ar' ? i18n('common.arabic') : i18n('common.english'),
      language: getLanguageCode(),
    };
  },

  computed: {
    ...mapGetters({
      // record: 'offer/form/record',
      findLoading: 'offer/form/findLoading',
      saveLoading: 'offer/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    // tab() {
    //   return getLanguageCode() == 'ar' ? 'english' : 'arabic'
    // }
  },
  
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'offer/form/doFind',
      doNew: 'offer/form/doNew',
      doUpdate: 'offer/form/doUpdate',
      doCreate: 'offer/form/doCreate',
      doGeneratePinCode: 'offer/form/doGeneratePinCode',
    }),
    i18n(code, args) {
      return i18n(code, args)
    },
    doCancel() {
      this.dialogVisible = false;
    },
    doChangeLanguage() {
      this.tab.toLowerCase() == 'arabic'
        ? this.language = 'ar'
        : this.language = 'en'
    },
  },
};
