import { render, staticRenderFns } from "./saryah-offer-list-table.vue?vue&type=template&id=6facea84&scoped=true&"
import script from "./saryah-offer-list-table.vue?vue&type=script&lang=js&"
export * from "./saryah-offer-list-table.vue?vue&type=script&lang=js&"
import style0 from "./saryah-offer-list-table.vue?vue&type=style&index=0&id=6facea84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6facea84",
  null
  
)

export default component.exports
import {QTable,QInnerLoading,QSpinnerIos,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QInnerLoading,QSpinnerIos,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip,QCard})
