//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

export default {
  name: 'app-store-offer-view-modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    }, 
  },

  data() {
    return {
      language: getLanguageCode(),
    };
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    i18n(code, args) {
      return i18n(code, args);
    },
    doClose() {
      this.$emit('close')
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
    presenterDateTime(date) {
      // const val = OfferModel.presenter(row, fieldName)
      return date ? moment(date).locale(this.currentLanguageCode).format("D MMM, YYYY hh:mm a") : 'ـــــــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
  },
};
